/* tslint:disable */
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as Styles from "../components/Layout/styles"
import SEO from "../components/seo"
import Terms from "../components/Terms"

const TermsAndUse = () => (
  <Layout>
    <SEO title="Termos de Uso" />
    <Styles.Policy>
      <Styles.MenuPolicy>
        <Styles.MenuPolicyItem>
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
        </Styles.MenuPolicyItem>
        <Styles.MenuPolicyItem>
          <Link activeClassName="active" to="/termos-de-uso">
            Termos de Uso
          </Link>
        </Styles.MenuPolicyItem>
      </Styles.MenuPolicy>
      <Styles.LegalText>
        <Terms />
      </Styles.LegalText>
    </Styles.Policy>
  </Layout>
)

export default TermsAndUse
